<style scoped>
	.theme-switch{cursor:pointer}
	.settings{font-size:10px;text-transform:capitalize;padding:0 5px 0 10px;color:#9D9D9D;font-weight: 600;text-align: left;}
	.username{font-size:12px;color:#666666;}
  .header_menu.v-list-item { min-height: 40px !important }
  .theme--dark .username{color:#C4C4C4;}
  ::v-deep.v-menu__content{top: 65px !important;right: 35px !important;left: auto !important;}
</style>

<template>
    <v-menu class="headerDropdown" max-width="190" right bottom :offset-y="true" transition="slide-y-transition" :position-y="10" rounded>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
              <v-badge bordered bottom color="deep-purple accent-4" dot offset-x="10" offset-y="10">
                <v-avatar size="40">
                  <v-img src="https://cdn.vuetifyjs.com/images/lists/2.jpg"></v-img>
                </v-avatar>
              </v-badge>
				<div class="settings">Welcome,<br><span class="username">{{ profileData.accountName }}</span></div>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size:14px;height:14px;width:14px;"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path></svg>
				<v-spacer></v-spacer>
          </v-btn>
		  <v-img :src="Switch" @click="themeChange()" class="theme-switch" max-width="30" max-height="30"></v-img>
        </template>
        <v-list class="py-0">
          <v-list-item class="body-2 line-height20 header_menu"> User ID: {{ profileData.accountId }}</v-list-item>
          <v-list-item class="body-2 header_menu"> Broker   : {{this.$store.state.currentBroker}} </v-list-item>
          <v-list-item class="body-2 header_menu" @click="logout"><a>Logout</a>
          </v-list-item>
        </v-list>
      </v-menu>
</template>

<script>

import Switch from "../assets/themeswitch.svg";
import { mixin as clickaway } from 'vue-clickaway'
import { mapState,mapMutations } from "vuex"
export default {
  
  mixins: [clickaway],

  data() {
    return {
      Switch: Switch,
      headerDropdown: false,
      userDetails: this.profileData,
    };
  },
  
  mounted() {
      const theme = localStorage.getItem("dark_theme");
      if (theme) {
          if (theme == "true") {
              this.$vuetify.theme.dark = true;
              this.updateThemeVal("dark");
          } else {
              this.$vuetify.theme.dark = false;
              this.updateThemeVal("light");
          }
      }
       this.$store.dispatch("userDetails/customerDetails");
  },

  methods: {

    ...mapMutations(['updateThemeVal']),

    themeChange() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      // console.log((this.$vuetify.theme.dark.toString()=="true")?"dark":"light")
      this.updateThemeVal((this.$vuetify.theme.dark.toString()=="true")?"dark":"light");
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },

    logout() {
      this.$router.push('/').catch(() => {});
    },

    away() {
      this.headerDropdown = false;
    },
  },

  computed: { 
      ...mapState('userDetails', ['profileData','global']),
  },
};
</script>
