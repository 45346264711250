<style>
    nav.menulist .v-tabs-slider-wrapper{ width: 4px !important; }
</style>
<style scoped>
    header{ box-shadow: none !important; }
    nav.menulist .logo { float: left; width: 100%; }
    nav.menulist .logo img { margin: 0 auto; display: block; padding: 20px 0; }
    nav.menulist .v-tab {min-width: 64px !important;padding:6px 0px !important}
    nav.menulist .v-tab:hover .body-2{color:#2ea6f5}
    nav.menulist .v-tab--active .body-2{color:#2ea6f5!important }
    nav.menulist .v-tabs--vertical > .v-tabs-bar .v-tab { height: 55px; display: block; width: 50px; padding-top: 5px; }
    nav.menulist .v-tabs--vertical > .v-tabs-bar .v-tab:hover .submenu-arrow { transform: rotate(270deg); }
    nav.menulist .submenu-arrow { position: absolute; top: 40%;right: 6px; transform: rotate(90deg); transition: transform 0.2s; }
    nav.menulist.v-navigation-drawer{
    z-index: 7;}
    nav.menulist.v-navigation-drawer--is-mobile + .submenu { height: 100vh; top: 0; }
    nav.menulist .v-tabs .body-2 { font-size: 0.7rem !important; text-transform: capitalize;color: #929292; }
    nav.menulist .v-tab img,nav.menulist .v-tab img.submenu-arrow,
    nav.menulist .v-tab.v-tab--active .submenu-arrow,
    nav.menulist .v-tab.v-tab--active .active_img{display:inline-block}
    nav.menulist .v-tab.v-tab--active img,
    nav.menulist .menu .active_img{display:none}
    .v-list.submenu { transition-delay: 0; opacity: 1; transition: left 0.2s; position: fixed; z-index: 10; left: 64px; height: calc(100vh - 70px); top: 70px; width: 180px; box-shadow: 6px 2px 7px rgb(0 0 0 / 7%); }
    .v-list.submenu .v-list-item__title { font-weight: 600 ;font-size: 0.75rem }
    .v-list.submenu.v-enter-active, .v-list.submenu.v-enter-to { left: 50px; opacity: 0; }
    .submenu .v-list-item--active { pointer-events: none; background: #D2EDFF; }
    .theme--dark .v-list.submenu {background-color: #2A2E32;}
    .theme--dark .v-list.submenu .v-list-item__title{color:#fff;font-weight:300}
    .theme--dark .v-list.submenu .v-list-item--active{background: #1e1e1e; }
    .z-index{z-index:6}
</style>
<template>
<div>
    <div class="outer-layer-full" v-on:mouseover="closesubmenu()" v-if="submenu.length"></div>
    <!-- <div class="outer-layer-full"></div> -->
    <v-navigation-drawer class="menulist" v-model="sidebarOpen" app width="64">
      <div width="86">
        <div class="logo"><img :src="logo" /></div>
        <v-tabs class="menu-list" vertical v-model="currentItem">
          <v-tab v-for="(is, i) in menu" :href="'#tab-' + is.view " :key=i>
            <div class="menu" v-if="is.items.length" v-on:mouseover="opensubmenu(is.items, is.view)" >
              <img :src="getImgUrl(is.svg)" />
              <img :src="getImgUrl(is.svg+'_a')" class="active_img"/>
              <img :src="getImgUrl('droparrow')" class="submenu-arrow"/>
              <div class="body-2">{{ is.text }}</div>
            </div>
            <div class="menu" v-else @click="$router.push('/'+is.view).catch(() => {})" v-on:mouseover="opensubmenu(is.items, is.view)">
              <img :src="getImgUrl(is.svg)" />
              <img :src="getImgUrl(is.svg+'_a')" class="active_img"/>
              <div class="body-2">{{ is.text }}</div>
            </div>
          </v-tab>
        </v-tabs>
      </div>
    </v-navigation-drawer>
    <transition delay="0">
      <v-list class="submenu" shaped v-if="submenu.length" nav dense v-bind:class="{ routerChange: submenuClick }">
        <v-list-item-group v-model="submenuSelectedItem" color="primary">
          <v-list-item v-for="(item, i) in submenu" :key="i" @click="$router.push(item.view + i).catch(() => {})">
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </transition>
    <v-app-bar app class="z-index">
      <v-app-bar-nav-icon @click="sidebarOpen = !sidebarOpen"></v-app-bar-nav-icon>
      <v-toolbar-title class="f-semi-18-12 ">{{ $route.meta.title }}</v-toolbar-title><v-spacer></v-spacer>
      <v-progress-linear :active="loading" :indeterminate="loading" absolute bottom rounded height="3" color="#2ea6f5"></v-progress-linear>
      <secondHeader />
    </v-app-bar>
  </div>
</template>
<script>
import { mapState } from "vuex";
import secondHeader from "../views/secondheader.vue";
import Logo from "../assets/logo.svg";
export default {
  data: () => ({
    sidebarOpen: null,
    submenu: [],
    submenuClick:false,
    recentsubmenu: [],
    recentview: "",
    currentItem: "tab-dashboard",
    logo: Logo,
    submenuSelectedItem: null,
    popularsectionactive: null,
    allfeaturesactive: null,
    otherinformationpactive: null,
    otherinformationactive: null,
    menu: [
      { text: "Home", svg: "dashboard1", view: "dashboard", items: [] },
      { text: "Orders", svg: "orders1", view: "orders", items: [] },
      // { text: "Positions", svg: "position1", view: "positions", items: [] },
      { text: "Positions", svg: "position1", view: "positions1", items: [] },
      // { text: "Payoff", svg: "payoff1", view: "payoffdiagram", items: [] },
      {
        text: "Option",
        view: "option",
        svg: "option1",
        items: [
          { view: "/option/optionchain/", text: "Option Chain" },
          { view: "/option/oianalysis/", text: "OI Analysis" },
          { view: "/option/oistatistics/", text: "OI Statistics" },
          { view: "/option/straddlecharts/", text: "Straddle Chart" },
          { view: "/option/totaloidifference/", text: "OI Difference" },
          { view: "/option/cumilativeoi/", text: "Cumilative OI" },
          { view: "/option/multistrikeoi/", text: "Multi Strike OI" },
          { view: "/option/pricevsoi/", text: "Price vs OI" },
          { view: "/option/ivchart/", text: "IV Chart" },
        ],
      },
      {
        text: "Futures",
        view: "futures",
        svg: "future1",
        items: [],
      },
      {
        text: "Calendar",
        svg: "calendar1",
        dropicon: "false",
        view: "calendar",
        items: [],
      },
      {
        text: "Fll, Dll",
        svg: "flldll1",
        view: "fiidii",
        items: []
        // items: [
        //   { text: "Fll", view: "/Fll/Fll" },
        //   { text: "Capital Market", view: "/Fll/dll" },
        // ],
      },
      { text: "News", svg: "news1", dropicon: "false", view: "news", items: [] },
      { text: "Plans", svg: "plan1", dropicon: "false", view: "planspage", items: [] },
      // {
      //   text: "Settings",
      //   svg: "settings1",
      //   dropicon: "false",
      //   view: "settings",
      //   items: [],
      // },
    ],
    // loading: false,
    
    getImgUrl(img) {
      var images = require.context("../assets/menuicons/", false, /\.svg$/);
      return images("./" + img + ".svg");
    },

    changeRoute(route, submenu, index) {
      if (this.$router.currentRoute.path != "/" + route) {
        if (submenu) {
          var croute =
            this.$router.currentRoute.path.split("/")[1] +
            "/" +
            this.$router.currentRoute.path.split("/")[2];
          if (croute != route) {
            this.$router.push("/" + route + "/" + index).catch(() => {});
            //this.$router.push({ path: '/'+route+'/'+index ,  params: {title: 'test title' }})
          }
        } else {
          this.$router.push("/" + route + "/" + index).catch(() => {});
          //this.$router.push({ path: '/'+route ,  params: {title: 'test title' }})
        }
      }
    },

  }),
  components: {
    secondHeader
  },
  methods: {
    opensubmenu: function(items, view) {
      this.isDisabled = true;
      this.recentsubmenu = items;
      this.recentview = view;
      this.submenu = items;
      if (this.$route.path.replace("/", "").split("/")[0] != view)
        this.submenuSelectedItem = null;
      else
        this.submenuSelectedItem = parseInt( this.$route.path.replace("/", "").split("/")[2]);
      this.currentItem =
        "tab-" + this.$route.path.replace("/", "").split("/")[0];
    },
    closesubmenu: function() {
      this.submenu = [];
      this.currentItem = "tab-" + this.$route.path.replace("/", "").split("/")[0];
    },
  },

  watch: {
    $route(to, from) {
      //this.currentItem = "tab-" + to.fullPath.split("/")[1];
      this.closesubmenu();
    },
  },

   computed: {
      ...mapState(['loading']),
    },

  mounted() {
    this.currentItem = "tab-" + this.$route.path.replace("/", "").split("/")[0];
  },
};
</script>

